import React from "react";
import Header from "../components/Header";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // width: "250px",
  height: "200px",
  maxWidth: "250px",
  borderRadius: "40px",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0px 7px 5px 5px #888888",
  },
}));

function Home() {
  const useStyles = makeStyles({
    imgs: {
      width: "45%",
      height: "45%",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "10%",
      marginBottom: "15%",
    },
  });

  const classes = useStyles();
  return (
    <>
      <Header
        PrimaryHeading={
          "One Stop Shop for All the Finance Products and Welcome Rewards in Singapore!"
        }
        SecondaryHeading={
          "Looking for Credit Cards, Brokerage Accounts, Loans? Start here!"
        }
      />

      <Container
        maxWidth="lg"
        style={{
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 6, md: 6 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/cards"}>
              <Item style={{ backgroundColor: "#A99EEF" }}>
                <div className={classes.imgs}>
                  <img alt="credit-card" src="/images/BS5/credit-card.png" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Cards
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/investments"}>
              <Item style={{ backgroundColor: "#FF9800" }}>
                <div className={classes.imgs}>
                  <img alt="investment" src="/images/BS5/investment.png" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Investments
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/insurances"}>
              <Item style={{ backgroundColor: "#78B6EF" }}>
                <div className={classes.imgs}>
                  <img alt="insurance" src="/images/BS5/insurance.png" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Insurances
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/loans"}>
              <Item style={{ backgroundColor: "#78EFAF" }}>
                <div className={classes.imgs}>
                  <img alt="loan" src="/images/BS5/loan.png" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Loans
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/savings"}>
              <Item style={{ backgroundColor: "#F0DF83" }}>
                <div className={classes.imgs}>
                  <img alt="accounting" src="/images/BS5/accounting.png" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Saving Accounts
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/blog"}>
              <Item style={{ backgroundColor: "#B67FE1" }}>
                <div className={classes.imgs}>
                  <img alt="blog" src="/images/BS5/blog.png" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Blog
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}></Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Link to={"/suggest-rewards"}>
              <Item style={{ backgroundColor: "#F27979" }}>
                <div className={classes.imgs}>
                  <img src="/images/BS5/gift.png" alt="gift" />
                </div>
                <Typography
                  variant="h5"
                  color="initial"
                  style={{ marginTop: "20px" }}
                >
                  Suggest Rewards
                </Typography>
              </Item>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={4}></Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Home;
