import React from "react";

const Zero = () => {
  return (
    <>
      <div className='w3-container w3-pale-green w3-center w3-opacity w3-padding-32'>
        <h1 className='w3-margin w3-xlarge'>
          Take Control Of Your Finances Today
        </h1>
      </div>
    </>
  );
};

export default Zero;
