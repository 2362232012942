import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  getSavingDetails,
  deleteSaving,
  clearSavingDeleteData,
} from "../actions/savingActions";

const SavingDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSavingDetails(match.params.id));
  }, [dispatch, match]);

  const { userInfo } = useSelector((state) => state.userLogin);

  const { loading, error, savingDetails } = useSelector(
    (state) => state.savingDetails
  );

  const {
    loading: loadingSavingDelete,
    error: errorSavingDelete,
    message,
  } = useSelector((state) => state.deleteSaving);

  const handleDeleteSavingBtn = (id) => {
    dispatch(deleteSaving(id));
    setTimeout(() => {
      dispatch(clearSavingDeleteData());
      history.push("/savings");
    }, 1500);
  };

  let theme = createTheme({
    palette: {
      secondary: {
        main: "#bf151e",
      },
    },
  });

  theme = responsiveFontSizes(theme);
  const useStyles = makeStyles({
    teirDiv: {
      height: "80px",
      width: "100%",
    },
    TierTypo: {
      marginTop: "20px",
    },
    rewardOffer: {
      padding: "0.7%",

      boxShadow: "2px",
      borderRadius: "10px",
      margin: "10px",
      backgroundColor: "#ffecb3",
    },
    AccordionItemPanelPaddingLeft: {
      paddingLeft: "40px",
    },
  });

  const classes = useStyles();

  return (
    <>
      <Header
        PrimaryHeading={"Personal finance, wealth opinions and others "}
        SecondaryHeading={
          "Make the best out of your time with bite size friendly information!"
        }
      />

      <Container
        maxWidth='md'
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        {loading ? (
          <LinearProgress />
        ) : loadingSavingDelete ? (
          <LinearProgress />
        ) : errorSavingDelete ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {errorSavingDelete + " Redirecting to savings page..."}
          </div>
        ) : error ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {error}
          </div>
        ) : message ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "green" /* Green */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {message + " Redirecting to savings page..."}
          </div>
        ) : (
          <>
            {savingDetails &&
              savingDetails.map((item) => {
                return (
                  <Card style={{ border: "1px solid #a1a1a1" }} key={item._id} className="card-media">
                    <ThemeProvider theme={theme}>
                      {userInfo ? (
                        <div style={{ float: "right", padding: "5px" }}>
                          {userInfo.isAdmin ? (
                            <ButtonGroup
                              variant='outlined'
                              aria-label='outlined button group'
                            >
                              <Link to='/update-saving'>
                                <Button>Edit</Button>
                              </Link>
                              <Button
                                style={{marginLeft: "10px"}}
                                color='secondary'
                                onClick={() => {
                                  handleDeleteSavingBtn(item._id);
                                }}
                              >
                                Delete
                              </Button>
                            </ButtonGroup>
                          ) : null}
                        </div>
                      ) : null}

                      <CardHeader
                        title={item.title}
                        subheader={item["subtitle"]}
                      />

                      <CardMedia
                        style={{
                          [theme.breakpoints.up("sm")]: {
                            maxWidth: "50%",
                          },
                          maxWidth: "40%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        component='img'
                        title='citi'
                        image={item.image}
                      />

                      <Grid
                        container
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexWrap: "noWrap",
                        }}
                      >
                        <Grid item className={classes.teirDiv}>
                          <Typography
                            align='center'
                            variant='h6'
                            color='#383838'
                            className={classes.TierTypo}
                          >
                            {item.tiers["tier1"]}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          className={classes.teirDiv}
                          style={{
                            borderLeft: "1px solid #888888",
                            borderRight: "1px solid #888888",
                          }}
                        >
                          <Typography
                            align='center'
                            variant='h6'
                            color='#383838'
                            className={classes.TierTypo}
                          >
                            {item.tiers["tier2"]}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.teirDiv}>
                          <Typography
                            align='center'
                            variant='h6'
                            color='#383838'
                            className={classes.TierTypo}
                          >
                            {item.tiers["tier3"]}
                          </Typography>
                        </Grid>
                      </Grid>

                      {item["rewardOffer"].map((rewardItem) => {
                        if (
                          rewardItem.description !== "" &&
                          rewardItem.tag !== "" &&
                          rewardItem.link !== ""
                        ) {
                          return (
                            <div
                              className={classes.rewardOffer}
                              key={rewardItem.tag}
                            >
                              <Link
                                target='_blank'
                                to={{
                                  pathname: rewardItem.link,
                                }}
                              >
                                <Typography
                                  style={{ padding: "5px" }}
                                  variant='h6'
                                  color='initial'
                                  align='center'
                                >
                                  {rewardItem.description}
                                </Typography>
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Chip label={rewardItem.tag} />
                                </div>
                              </Link>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}

                      <Accordion allowZeroExpanded>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Key Features
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel
                            className={classes.AccordionItemPanelPaddingLeft}
                          >
                            <>
                              <ul>
                                {item.details["keyFeatures"].map((list) => {
                                  return (
                                    <li key={"ID:" + list.substr(-9)}>
                                      {list}
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Highlights
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel
                            className={classes.AccordionItemPanelPaddingLeft}
                          >
                            <>
                              <ul key={item.id}>
                                {item.details["repaymentSummary"].map(
                                  (list) => {
                                    return (
                                      <li key={"ID:" + list.substr(-9)}>
                                        {list}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </ThemeProvider>
                  </Card>
                );
              })}
          </>
        )}
      </Container>
    </>
  );
};

export default SavingDetailsPage;
