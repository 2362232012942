import {
  CARD_DETAILS_FAIL,
  CARD_DETAILS_REQUEST,
  CARD_DETAILS_SUCCESS,
  GET_CARDS_FAIL,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAIL,
  CLEAR_CARD_DELETE_DATA,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAIL,
  CLEAR_CARD_UPDATE_DATA,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  CLEAR_CARD_CREATE_DATA,
} from "../constants/cardConstants";

export const getCardsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CARDS_REQUEST:
      return { loading: true };
    case GET_CARDS_SUCCESS:
      return { loading: false, cards: action.payload };
    case GET_CARDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cardDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CARD_DETAILS_REQUEST:
      return { loading: true, ...state };
    case CARD_DETAILS_SUCCESS:
      return { loading: false, cardDetails: action.payload };
    case CARD_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteCardReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CARD_REQUEST:
      return { loading: true, ...state };
    case DELETE_CARD_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_CARD_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_CARD_DELETE_DATA:
      return { loading: null, message: null, error: null };
    default:
      return state;
  }
};

export const updateCardReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CARD_REQUEST:
      return { loading: true, ...state };
    case UPDATE_CARD_SUCCESS:
      return { loading: false, updatedCard: action.payload };
    case UPDATE_CARD_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_CARD_UPDATE_DATA:
      return { loading: null, updatedCard: null, error: null };
    default:
      return state;
  }
};

export const createCardReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CARD_REQUEST:
      return { loading: true, ...state };
    case CREATE_CARD_SUCCESS:
      return { loading: false, newCard: action.payload };
    case CREATE_CARD_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_CARD_CREATE_DATA:
      return { loading: null, newCard: null, error: null };
    default:
      return state;
  }
};
