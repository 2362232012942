import {
  CREATE_POST_FAIL,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  GET_POSTS_FAIL,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_TAGS_SUCCESS,
  POST_DETAILS_FAIL,
  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  DELETE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  CLEAR_POST_DELETE_DATA,
  CLEAR_POST_CREATE_DATA,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  CLEAR_POST_UPDATE_DATA,
} from "../constants/blogConstants";

export const getPostsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_POSTS_REQUEST:
      return { loading: true };
    case GET_POSTS_SUCCESS:
      return { loading: false, posts: action.payload?.data ?? [], tags: action.payload?.tags ?? [] };
    case GET_POSTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_DETAILS_REQUEST:
      return { loading: true, ...state };
    case POST_DETAILS_SUCCESS:
      return { loading: false, post: action.payload };
    case POST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPostReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_POST_REQUEST:
      return { loading: true, ...state };
    case CREATE_POST_SUCCESS:
      return { loading: false, newPost: action.payload };
    case CREATE_POST_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_POST_CREATE_DATA:
      return { loading: null, newPost: null, error: null };
    default:
      return state;
  }
};

export const deletePostReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_POST_REQUEST:
      return { loading: true, ...state };
    case DELETE_POST_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_POST_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_POST_DELETE_DATA:
      return { loading: null, message: null, error: null };
    default:
      return state;
  }
};

export const updatePostReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_POST_REQUEST:
      return { loading: true, ...state };
    case UPDATE_POST_SUCCESS:
      return { loading: false, updatedPost: action.payload };
    case UPDATE_POST_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_POST_UPDATE_DATA:
      return { loading: null, updatedPost: null, error: null };
    default:
      return state;
  }
};
