import {
  CREATE_POST_FAIL,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  GET_POSTS_FAIL,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_TAGS_SUCCESS,
  POST_DETAILS_FAIL,
  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  DELETE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  CLEAR_POST_DELETE_DATA,
  CLEAR_POST_CREATE_DATA,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  CLEAR_POST_UPDATE_DATA,
} from "../constants/blogConstants";
import { API_URL } from "../../backendRoute";
import axios from "axios";

export const getPostsList = () => (dispatch) => {
  dispatch({
    type: GET_POSTS_REQUEST,
  });

  axios
    .get(`${API_URL}/api/posts`)
    .then(({ data }) => {
      dispatch({
        type: GET_POSTS_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_POSTS_FAIL,
        payload: "Server Error",
        // err.response && err.response.data.message
        //   ? err.response.data.message
        //   : err.response,
      });
    });
};

export const getPostDetails = (id) => (dispatch) => {
  dispatch({ type: POST_DETAILS_REQUEST });
  axios
    .get(`${API_URL}/api/posts/${id}`)
    .then((response) => {
      dispatch({ type: POST_DETAILS_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({
        type: POST_DETAILS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.response,
      });
    });
};

export const createPost = ({
  title,
  description,
  tags,
  author,
  slug,
  richTextDescription,
  files,
  priority,
  uploadedImages
}) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', description);
  formData.append('tags', JSON.stringify(tags));
  formData.append('author', author);
  formData.append('slug', slug);
  formData.append('richTextDescription', richTextDescription);
  formData.append('image', files);
  formData.append('priority', priority);
  formData.append("contentImageSrc", JSON.stringify(uploadedImages.map(x => x.localSrc)));
  uploadedImages.map((image) => {
    formData.append("contentImage", image.file);
    return false;
  })

  return (dispatch) => {
    dispatch({ type: CREATE_POST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        `${API_URL}/api/posts/create`,
        formData,
        config
      )
      .then((response) => {
        dispatch({ type: CREATE_POST_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_POST_FAIL,
          payload:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.response,
        });
      });
  };
};

export const deletePost = (id) => (dispatch) => {
  dispatch({ type: DELETE_POST_REQUEST });

  axios
    .delete(`${API_URL}/api/posts/delete/${id}`)
    .then(() => {
      dispatch({ type: DELETE_POST_SUCCESS, payload: "Post Deleted!" });
    })
    .catch((err) => {
      dispatch({
        type: DELETE_POST_FAIL,
        payload: "Error deleting post",
        // err.response && err.response.data.message
        //   ? err.response.data.message
        //   : err.response,
      });
    });
};

export const clearPostDeleteData = () => (dispatch) => {
  dispatch({ type: CLEAR_POST_DELETE_DATA });
};
export const clearPostCreateData = () => (dispatch) => {
  dispatch({ type: CLEAR_POST_CREATE_DATA });
};

export const updatePost = ({
  id,
  title,
  description,
  tags,
  author,
  slug,
  richTextDescription,
  files,
  priority,
  uploadedImages
}) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("title", title);
  formData.append("description", description);
  formData.append('tags', JSON.stringify(tags));
  formData.append('author', author);
  formData.append('slug', slug);
  formData.append('richTextDescription', richTextDescription);
  formData.append("image", files);
  formData.append('priority', priority);
  formData.append("contentImageSrc", JSON.stringify(uploadedImages.map(x => x.localSrc)));
  uploadedImages.map((image) => {
    formData.append("contentImage", image.file);
    return false;
  })

  return (dispatch) => {
    dispatch({ type: UPDATE_POST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .put(
        `${API_URL}/api/posts/update`,
        formData,
        config
      )
      .then((response) => {
        dispatch({ type: UPDATE_POST_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_POST_FAIL,
          payload:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.response,
        });
      });
  };
};

export const clearPostUpdateData = () => (dispatch) => {
  dispatch({ type: CLEAR_POST_UPDATE_DATA });
};
