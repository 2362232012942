export const GET_CARDS_REQUEST = "GET_CARDS_REQUEST";
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_FAIL = "GET_CARDS_FAIL";

export const CARD_DETAILS_REQUEST = "CARD_DETAILS_REQUEST";
export const CARD_DETAILS_SUCCESS = "CARD_DETAILS_SUCCESS";
export const CARD_DETAILS_FAIL = "CARD_DETAILS_FAIL";

export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST ";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS ";
export const DELETE_CARD_FAIL = "DELETE_CARD_FAIL ";

export const CLEAR_CARD_DELETE_DATA = "CLEAR_CARD_DELETE_DATA ";
export const CLEAR_CARD_CREATE_DATA = "CLEAR_CARD_CREATE_DATA";
export const CLEAR_CARD_UPDATE_DATA = "CLEAR_CARD_UPDATE_DATA";

export const UPDATE_CARD_REQUEST = "UPDATE_CARD_REQUEST";
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS";
export const UPDATE_CARD_FAIL = "UPDATE_CARD_FAIL";

export const CREATE_CARD_REQUEST = "CREATE_CARD_REQUEST";
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAIL = "CREATE_CARD_FAIL";
