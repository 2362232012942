import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, Redirect } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import isEmail from "validator/lib/isEmail";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions"; //
import { resetUserInfo } from "../actions/userActions"; //

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "10px",
    backgroundColor: "#1976d2",
  },
  form: {
    width: "100%",
    marginTop: "20px",
  },
  submit: {
    margin: "5px 0px 10px",
  },
}));

const SignUp = () => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userSuccess, setUserSuccess] = useState("");

  const [emailValidationError, setEmailValidationError] = useState("");
  const [nameValidationError, setNameValidationError] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    useState("");

  const { loading, error, userInfo } = useSelector(
    (state) => state.userRegister
  );

  const dispatch = useDispatch();

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.length === 0) {
      setNameValidationError(true);
    } else {
      setNameValidationError(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    if (e.target.value.length === 0) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    if (e.target.value.length === 0) {
      setPasswordValidationError(true);
    } else {
      setPasswordValidationError(false);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    if (e.target.value.length === 0) {
      setPasswordValidationError(true);
    } else {
      setPasswordValidationError(false);
    }
  };

  const reqValidationCheck = () => {
    if (isEmail(email) === false) {
      setEmailValidationError(true);
    }

    if (email.length === 0) {
      setEmailValidationError(true);
    }

    if (name.length === 0) {
      setNameValidationError(true);
    }
    if (password.length === 0) {
      setPasswordValidationError(true);
    }
    if (password.length < 6) {
      setPasswordValidationError(true);
      setPasswordValidationErrorMessage(
        "Password must be at least 6 characters long"
      );
    }
    if (password !== confirmPassword) {
      setPasswordValidationError(true);
      setPasswordValidationErrorMessage(
        "Password must be same as confirm password"
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    reqValidationCheck();

    if (
      isEmail(email) === false ||
      passwordValidationError === true ||
      password !== confirmPassword
    ) {
      console.log("Fields validation error");
    } else {
      dispatch(register(name, email, password));
    }
  };

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        setUserSuccess(true);
        dispatch(resetUserInfo());
      }, 2000);
    }
  }, [userInfo, dispatch]);

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component='h1'
          variant='h5'
          style={{ marginBottom: "10px" }}
        >
          Sign up
        </Typography>

        {error && (
          <Alert variant='filled' severity='error'>
            {error}
          </Alert>
        )}
        {userInfo && (
          <>
            <Alert variant='filled' severity='success'>
              User registration successfull. Redirecting to login page...
            </Alert>
            {userSuccess ? <Redirect to='/login' /> : null}
          </>
        )}

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={nameValidationError}
                autoComplete='name'
                variant='outlined'
                name='Name'
                required
                fullWidth
                id='Name'
                label='Your Name'
                autoFocus
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={emailValidationError}
                helperText={emailValidationError ? "Email must be valid" : null}
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={passwordValidationError}
                helperText={
                  passwordValidationError
                    ? passwordValidationErrorMessage
                    : null
                }
                variant='outlined'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={passwordValidationError}
                variant='outlined'
                helperText={
                  passwordValidationError
                    ? passwordValidationErrorMessage
                    : null
                }
                required
                fullWidth
                name='Confirm password'
                label='Confirm Password'
                type='password'
                id='confirmpassword'
                onChange={handleConfirmPasswordChange}
              />
            </Grid>
          </Grid>
          <br />
          {loading && <LinearProgress />}

          <Button
            type='submit'
            disabled={
              name.length === 0
                ? true
                : email.length === 0
                ? true
                : password.length === 0
                ? true
                : null
            }
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              Already have an account? &nbsp;
              <Link to='/login' variant='body2'>
                <b>Login</b>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default SignUp;
