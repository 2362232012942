import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { createPost, clearPostCreateData } from "../actions/blogActions";
import { Link } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./blog.css";

const CreateNewPost = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPostCreateData());
  }, [dispatch]);

  const { loading, error, newPost } = useSelector((state) => state.newPost);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [priority, setPriority] = useState(0);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [author, setAuthor] = useState("");
  // const [richTextDescription, setRichTextDescription] = useState("");
  const [files, setFiles] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleSubmit = (e) => {
    const richTextDescription = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    e.preventDefault();
    dispatch(
      createPost({
        title,
        slug,
        priority,
        description,
        tags,
        author,
        richTextDescription,
        files,
        uploadedImages,
      })
    );
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };
  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleTagsChange = (e) => {
    setTags(e.target.value.split(","));
  };
  const handleAuthorChange = (e) => {
    setAuthor(e.target.value);
  };
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    }

    setFiles(file);
  }

  const onEditorStateChange = (editorState, event) => {
    if (event) {
      event.preventDefault();
    }
    setEditorState(editorState);
  };

  const classes = {
    textfieldPadding: {
      // marginLeft: "30px",
      marginBottom: "15px",
    },
  };

  const uploadImageCallBack = async (file) => {
    let uploadedImage = uploadedImages;
    const imageObject = {
        file: file,
        localSrc: URL.createObjectURL(file),
    }

    uploadedImage.push(imageObject);
    setUploadedImages(uploadedImage);
    return new Promise((resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <>
      <Container className="ckEditor" component='main' maxWidth='lg' style={{ marginTop: "80px" }}>
        <br />
        <Typography align='center' variant='h4'>
          Create a new blog post
        </Typography>
        <br />
        {error && (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {error}
          </div>
        )}

        {loading && <LinearProgress />}
        {newPost && (
          <div
            style={{
              padding: "20px",
              backgroundColor: "green",
              color: "white",
              marginBottom: "15px",
            }}
          >
            Post published successfully. Go to{" "}
            <Link to='/blog'>
              {" "}
              <b>blog page. </b>
            </Link>
          </div>
        )}
        <br />
        <form noValidate autoComplete='on'>
          <Grid container spacing={3}>
            <Grid item xs={24} md={12}>
              <label htmlFor="contained-button-file">
                <input style={{display: "none"}} accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => handleUploadImage(e)} />
                <Button variant="contained" component="span" style={{marginBottom: "15px"}}>
                  Upload Image
                </Button>
              </label>

              {
                previewImage && (
                  <div style={{marginBottom: "15px"}}>
                    <img style={{maxWidth: "360px"}} src={previewImage} alt="upload"/>
                  </div>
                )
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='title'
                label='Title'
                fullWidth={true}
                variant='standard'
                style={classes.textfieldPadding}
                // inputProps={{ maxLength: 50 }}
                onChange={handleTitleChange}
              />
              <TextField
                id='slug'
                label='Slug Url'
                fullWidth={true}
                variant='standard'
                style={classes.textfieldPadding}
                // inputProps={{ maxLength: 50 }}
                onChange={handleSlugChange}
              />
              <TextField
                style={classes.textfieldPadding}
                id='priority'
                label='Priority'
                type="number"
                fullWidth={true}
                variant='standard'
                // value={title}
                onChange={handlePriorityChange}
              />
              <TextField
                variant='standard'
                fullWidth
                placeholder='Short Description'
                style={classes.textfieldPadding}
                // style={{ width: 565 }}
                onChange={handleDescriptionChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                variant='standard'
                id='image'
                label='Image URL'
                fullWidth={true}
                onChange={handleImageURLChange}
              /> */}
              <TextField
                variant='standard'
                id='author'
                label='Author'
                fullWidth={true}
                style={classes.textfieldPadding}
                onChange={handleAuthorChange}
              />
              <TextField
                variant='standard'
                id='tags'
                label='Tags'
                placeholder='Input comma separated values. eg; Finance,Investment'
                className="tag-input"
                fullWidth={true}
                style={classes.textfieldPadding}
                onChange={handleTagsChange}
              />
            </Grid>
          </Grid>
          <Typography variant=''>Detailed description:</Typography>
          {/* <RichTextEditor /> */}
          {/*  */}
          <div style={{marginTop: "10px"}}>
            <Editor
              // initialEditorState={editorState}
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  uploadCallback: (file) => uploadImageCallBack(file),
                  alignmentEnabled: true,
                  defaultSize: {
                      height: 'auto',
                      width: 'auto',
                  }
                }
              }}
            />
          </div>
          {/*  */}
          <Button
            type='submit'
            // fullWidth
            variant='contained'
            color='primary'
            style={{
              marginTop: "2%",
              marginBottom: "2%",
            }}
            onClick={(e) => handleSubmit(e)}
          >
            Publish Post
          </Button>{" "}
          {newPost && <DoneIcon />}
        </form>
      </Container>
    </>
  );
};

export default CreateNewPost;
