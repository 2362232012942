import {
  LOAN_DETAILS_FAIL,
  LOAN_DETAILS_REQUEST,
  LOAN_DETAILS_SUCCESS,
  GET_LOANS_FAIL,
  GET_LOANS_REQUEST,
  GET_LOANS_SUCCESS,
  DELETE_LOAN_REQUEST,
  DELETE_LOAN_SUCCESS,
  DELETE_LOAN_FAIL,
  CLEAR_LOAN_DELETE_DATA,
  UPDATE_LOAN_REQUEST,
  UPDATE_LOAN_SUCCESS,
  UPDATE_LOAN_FAIL,
  CLEAR_LOAN_UPDATE_DATA,
  CREATE_LOAN_REQUEST,
  CREATE_LOAN_SUCCESS,
  CREATE_LOAN_FAIL,
  CLEAR_LOAN_CREATE_DATA,
} from "../constants/loanConstants";

export const getLoansListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LOANS_REQUEST:
      return { loading: true };
    case GET_LOANS_SUCCESS:
      return { loading: false, loans: action.payload };
    case GET_LOANS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const loanDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_DETAILS_REQUEST:
      return { loading: true, ...state };
    case LOAN_DETAILS_SUCCESS:
      return { loading: false, loanDetails: action.payload };
    case LOAN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_LOAN_REQUEST:
      return { loading: true, ...state };
    case DELETE_LOAN_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_LOAN_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_LOAN_DELETE_DATA:
      return { loading: null, message: null, error: null };
    default:
      return state;
  }
};

export const updateLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOAN_REQUEST:
      return { loading: true, ...state };
    case UPDATE_LOAN_SUCCESS:
      return { loading: false, updatedLoan: action.payload };
    case UPDATE_LOAN_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_LOAN_UPDATE_DATA:
      return { loading: null, updatedLoan: null, error: null };
    default:
      return state;
  }
};

export const createLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_LOAN_REQUEST:
      return { loading: true, ...state };
    case CREATE_LOAN_SUCCESS:
      return { loading: false, newLoan: action.payload };
    case CREATE_LOAN_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_LOAN_CREATE_DATA:
      return { loading: null, newLoan: null, error: null };
    default:
      return state;
  }
};
