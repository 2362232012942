import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import { Card, CardContent, CardMedia, Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { getPostsList } from "./actions/blogActions";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const Blog = () => {
  const useStyles = makeStyles({
    selectedCAT: {
      border: "2px solid #1976d2",
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostsList());
  }, [dispatch]);
  const [currentCategory, setCurrentCategory] = useState("All");

  const cardCategories = [
    "All",
    "Food",
    "Stock Trading",
    "Business",
    "Money",
    "Finance",
    "Digital",
    "Technology",
    "Marketing",
    "Investment",
    "Gaming",
  ];

  const { loading, error, posts, tags } = useSelector((state) => state.blogPosts);
  console.log(tags)

  const handleCategoryChange = (e) => {
    setCurrentCategory(e.target.innerText);
  };

  let filteredCardList = [];

  if (currentCategory === "All") {
    filteredCardList = posts;
  } else {
    filteredCardList = posts.filter((post) =>
      post.tags.some((tag) => tag === currentCategory)
    );
  }

  return (
    <>
      <Header
        PrimaryHeading={"Personal finance, wealth opinions and others "}
        SecondaryHeading={
          "Make the best out of your time with bite size friendly information!"
        }
      />

      <Container
        maxWidth='lg'
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",

            justifyContent: "center",
          }}
        >
          {(tags ? ["All", ...tags] : ["All"])?.map((cat) => {
            return (
              <Chip
                key={cat}
                style={{ margin: "5px 5px 25px 5px" }}
                className={cat === currentCategory ? classes.selectedCAT : null}
                label={cat}
                variant='outlined'
                clickable
                id={cat}
                onClick={handleCategoryChange}
              />
            );
          })}
        </Box>

        {loading ? (
          <LinearProgress />
        ) : error ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {error}
          </div>
        ) : (
          <Grid container spacing={4}>
            {filteredCardList &&
              filteredCardList.map((item) => {
                return (
                  <Grid item sm={12} md={4} key={item._id}>
                    <Card style={{ maxWidth: "350px" }}>
                      <div
                        style={{
                          // backgroundColor: "#1976d2",
                          height: "50px",
                          marginBottom: "20px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <Typography
                          fontSize='18px'
                          color='inherit'
                          align='center'
                        >
                          {item.title}
                        </Typography>
                      </div>
                      <CardMedia
                        style={{
                          maxWidth: "95%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        component='img'
                        title='blogpost image'
                        image={item.image}
                      />

                      <CardContent
                        style={{
                          maxWidth: "80%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            // height: "120px",
                            width: "100%",
                            padding: "5px",
                          }}
                        >
                          <Divider />
                          <div
                            style={{
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            <Typography
                              align='center'
                              variant='body1'
                              color='inherit'
                            >
                              {item.description.substring(0, 150) + " ......"}
                            </Typography>
                          </div>
                        </div>
                        <Divider />
                      </CardContent>

                      <Link to={`/posts/${item.slug ? item.slug : item._id}`}>
                        <Button variant='contained' fullWidth color='primary'>
                          Read more !
                        </Button>
                      </Link>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Blog;
