import { API_URL } from "../backendRoute";
import axios from "axios";
import {
  SAVING_DETAILS_FAIL,
  SAVING_DETAILS_REQUEST,
  SAVING_DETAILS_SUCCESS,
  GET_SAVINGS_FAIL,
  GET_SAVINGS_REQUEST,
  GET_SAVINGS_SUCCESS,
  DELETE_SAVING_REQUEST,
  DELETE_SAVING_SUCCESS,
  DELETE_SAVING_FAIL,
  CLEAR_SAVING_DELETE_DATA,
  CLEAR_SAVING_UPDATE_DATA,
  UPDATE_SAVING_REQUEST,
  UPDATE_SAVING_SUCCESS,
  UPDATE_SAVING_FAIL,
  CREATE_SAVING_REQUEST,
  CREATE_SAVING_SUCCESS,
  CREATE_SAVING_FAIL,
  CLEAR_SAVING_CREATE_DATA,
} from "../constants/savingConstants";

export const getSavingsList = () => (dispatch) => {
  dispatch({
    type: GET_SAVINGS_REQUEST,
  });

  axios
    .get(`${API_URL}/api/savings`)
    .then(({ data }) => {
      dispatch({
        type: GET_SAVINGS_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SAVINGS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.response
            ? err.response
            : "Server Error",
      });
    });
};

export const getSavingDetails = (id) => (dispatch) => {
  dispatch({ type: SAVING_DETAILS_REQUEST });
  axios
    .get(`${API_URL}/api/savings/${id}`)
    .then((response) => {
      dispatch({ type: SAVING_DETAILS_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({
        type: SAVING_DETAILS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.response
            ? err.response
            : "Server Error",
      });
    });
};
///

export const deleteSaving = (id) => (dispatch) => {
  dispatch({ type: DELETE_SAVING_REQUEST });

  axios
    .delete(`${API_URL}/api/savings/delete/${id}`)
    .then(() => {
      dispatch({ type: DELETE_SAVING_SUCCESS, payload: "Record Deleted!" });
    })
    .catch((err) => {
      dispatch({
        type: DELETE_SAVING_FAIL,
        payload: "Error deleting record",
        // err.response && err.response.data.message
        //   ? err.response.data.message
        //   : err.response,
      });
    });
};

export const clearSavingDeleteData = () => (dispatch) => {
  dispatch({ type: CLEAR_SAVING_DELETE_DATA });
};

export const updateSaving = ({
  id,
  title,
  subtitle,
  tiers, //object
  rewardOffer, //array
  category,
  slug,
  details, //object
  files,
  priority,
}) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("title", title);
  formData.append("subtitle", subtitle);
  formData.append("tiers", JSON.stringify(tiers));
  formData.append("rewardOffer", JSON.stringify(rewardOffer));
  formData.append("category", category);
  formData.append("slug", slug);
  formData.append("details", JSON.stringify(details));
  formData.append("image", files);
  formData.append('priority', priority);

  return (dispatch) => {
    dispatch({ type: UPDATE_SAVING_REQUEST });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .put(
        `${API_URL}/api/savings/update`,
        formData,
        config
      )
      .then((response) => {
        dispatch({ type: UPDATE_SAVING_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SAVING_FAIL,
          payload:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.response,
        });
      });
  };
};

export const clearSavingUpdateData = () => (dispatch) => {
  dispatch({ type: CLEAR_SAVING_UPDATE_DATA });
};

//
//

export const createSaving = ({
  title,
  subtitle,
  tiers, //object
  rewardOffer, //array
  category,
  slug,
  details, //object
  files,
  priority,
}) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('subtitle', subtitle);
  formData.append('tiers', JSON.stringify(tiers));
  formData.append('rewardOffer', JSON.stringify(rewardOffer));
  formData.append('category', category);
  formData.append('slug', slug);
  formData.append('details', JSON.stringify(details));
  formData.append('image', files);
  formData.append('priority', priority);

  return (dispatch) => {
    dispatch({ type: CREATE_SAVING_REQUEST });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        `${API_URL}/api/savings/create`,
        formData,
        config
      )
      .then((response) => {
        dispatch({ type: CREATE_SAVING_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_SAVING_FAIL,
          payload:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.response,
        });
      });
  };
};

export const clearSavingCreateData = () => (dispatch) => {
  dispatch({ type: CLEAR_SAVING_CREATE_DATA });
};
