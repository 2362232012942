import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvestment,
  clearInvestmentCreateData,
} from "../../actions/investmentsActions";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";

const CreateInvestment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearInvestmentCreateData());
  }, [dispatch]);

  const { loading, error, newInvestment } = useSelector(
    (state) => state.newInvestment
  );

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [priority, setPriority] = useState(0);
  const [subtitle, setSubtitle] = useState("");
  const [category, setCategory] = useState("");

  const [tier1, setTier1] = useState("");
  const [tier2, setTier2] = useState("");
  const [tier3, setTier3] = useState("");
  const [rewardOffer, setRewardOffer] = useState([
    { description: "", tag: "", link: "" },
  ]);
  const [files, setFiles] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [keyFeatures, setKeyFeatures] = useState([]);
  const [commisionFee, setCommisionFees] = useState([]);
  const [additionalDetail, setAdditionalDetails] = useState([]);

  let theme = createTheme({
    palette: {
      secondary: {
        main: "#bf151e",
      },
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createInvestment({
        title,
        slug,
        priority,
        subtitle,
        tiers: {
          tier1,
          tier2,
          tier3,
        }, //object
        category,
        rewardOffer,
        details: {
          keyFeatures,
          commisionFees: commisionFee,
          additionalDetails: additionalDetail,
        }, //object
        files,
      })
    );
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };
  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };
  const handleSubtitleChange = (e) => {
    setSubtitle(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  const handleTier1Change = (e) => {
    setTier1(e.target.value);
  };
  const handleTier2Change = (e) => {
    setTier2(e.target.value);
  };
  const handleTier3Change = (e) => {
    setTier3(e.target.value);
  };

  const handleOfferChange = (e, index) => {
    const newOfferArr = [...rewardOffer];
    newOfferArr[index][e.target.name] = e.target.value;
    setRewardOffer(newOfferArr);
  };

  const addRewardOffer = () => {
    setRewardOffer([...rewardOffer, { description: "", tag: "", link: "" }]);
  };
  const removeRewardOffer = (index) => {
    const offerArrNew = [...rewardOffer];
    offerArrNew.splice(index, 1);
    setRewardOffer(offerArrNew);
  };
  //

  const handleKeyFeatureChange = (e, index) => {
    const arrFeature = [...keyFeatures];
    arrFeature[index] = e.target.value;
    setKeyFeatures(arrFeature);
  };

  const addKeyFeature = () => {
    setKeyFeatures([...keyFeatures, ""]);
  };

  const removeKeyFeature = (index) => {
    const newKeyFeatureArr = [...keyFeatures];
    newKeyFeatureArr.splice(index, 1);
    setKeyFeatures(newKeyFeatureArr);
  };

  //
  const handleCommisionFeeChange = (e, index) => {
    const arrCFee = [...commisionFee];
    arrCFee[index] = e.target.value;
    setCommisionFees(arrCFee);
  };

  const addCommisionFee = () => {
    setCommisionFees([...commisionFee, ""]);
  };

  const removeCommisionFee = (index) => {
    const newCommisionFeeArr = [...commisionFee];
    newCommisionFeeArr.splice(index, 1);
    setCommisionFees(newCommisionFeeArr);
  };
  //

  const handleAdditionalDetailChange = (e, index) => {
    const arrADetails = [...additionalDetail];
    arrADetails[index] = e.target.value;
    setAdditionalDetails(arrADetails);
  };

  const addAdditionalDetail = () => {
    setAdditionalDetails([...additionalDetail, ""]);
  };

  const removeAdditionalDetail = (index) => {
    const newAdditionalDetailArr = [...additionalDetail];
    newAdditionalDetailArr.splice(index, 1);
    setAdditionalDetails(newAdditionalDetailArr);
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    };

    setFiles(file);
  };

  const classes = {
    textfieldPadding: {
      // marginLeft: "30px",
      marginBottom: "15px",
    },
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='lg' style={{ marginTop: "80px" }}>
          <br />
          <Typography align='center' variant='h4'>
            Create insurance
          </Typography>
          <br />
          {error && (
            <div
              style={{
                padding: "20px",
                backgroundColor: "#f44336" /* Red */,
                color: "white",
                marginBottom: "15px",
              }}
            >
              {error}
            </div>
          )}

          {loading && <LinearProgress />}
          {newInvestment && (
            <div
              style={{
                padding: "20px",
                backgroundColor: "green",
                color: "white",
                marginBottom: "15px",
              }}
            >
              Record created successfully. Go to{" "}
              <Link to='/insurances'>
                {" "}
                <b>main page. </b>
              </Link>
            </div>
          )}
          <br />
          <form noValidate autoComplete='on'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <label htmlFor='contained-button-file'>
                  <input
                    style={{ display: "none" }}
                    accept='image/*'
                    id='contained-button-file'
                    multiple
                    type='file'
                    onChange={(e) => handleUploadImage(e)}
                  />
                  <Button
                    variant='contained'
                    component='span'
                    style={{ marginBottom: "15px" }}
                  >
                    Upload Image
                  </Button>
                </label>

                {previewImage && (
                  <div style={{ marginBottom: "15px" }}>
                    <img
                      style={{ maxWidth: "360px" }}
                      src={previewImage}
                      alt='upload'
                    />
                  </div>
                )}

                <TextField
                  style={classes.textfieldPadding}
                  id='title'
                  label='Title'
                  fullWidth={true}
                  variant='outlined'
                  // value={title}
                  onChange={handleTitleChange}
                />

                <TextField
                  style={classes.textfieldPadding}
                  id='slug'
                  label='Slug Url'
                  fullWidth={true}
                  variant='outlined'
                  // value={title}
                  onChange={handleSlugChange}
                />

                <TextField
                  style={classes.textfieldPadding}
                  id='priority'
                  label='Priority'
                  type="number"
                  fullWidth={true}
                  variant='outlined'
                  // value={title}
                  onChange={handlePriorityChange}
                />

                <TextField
                  style={classes.textfieldPadding}
                  id='subtitle'
                  label='Subtitle'
                  fullWidth={true}
                  variant='outlined'
                  // value={subtitle}
                  onChange={handleSubtitleChange}
                />

                {/* <TextField
                  style={classes.textfieldPadding}
                  id='image'
                  label='Image URL'
                  fullWidth={true}
                  variant='outlined'
                  // value={imageURL}
                  onChange={handleImageURLChange}
                /> */}

                <FormControl fullWidth style={classes.textfieldPadding}>
                  <InputLabel id='category'>Category</InputLabel>
                  <Select
                    labelId='category'
                    id='category-select'
                    //   value={category}
                    label='Category'
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value={"American Express"}>
                      American Express
                    </MenuItem>
                    <MenuItem value={"CIMB"}>CIMB</MenuItem>
                    <MenuItem value={"Citibank"}>Citibank</MenuItem>
                    <MenuItem value={"DBS"}>DBS</MenuItem>
                    <MenuItem value={"HSBC"}>HSBC</MenuItem>
                    <MenuItem value={"OCBC"}>OCBC</MenuItem>
                    <MenuItem value={"POSB"}>POSB</MenuItem>
                    <MenuItem value={"Standard Chartered"}>
                      Standard Chartered
                    </MenuItem>
                    <MenuItem value={"UOB"}>UOB</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  style={classes.textfieldPadding}
                  id='tier1'
                  label='Tier 1'
                  fullWidth={true}
                  variant='outlined'
                  // value={tier1}
                  onChange={handleTier1Change}
                />

                <TextField
                  style={classes.textfieldPadding}
                  id='tier2'
                  label='Tier 2'
                  fullWidth={true}
                  variant='outlined'
                  // value={tier2}
                  onChange={handleTier2Change}
                />

                <TextField
                  style={classes.textfieldPadding}
                  id='tier3'
                  label='Tier 3'
                  fullWidth={true}
                  variant='outlined'
                  // value={tier3}
                  onChange={handleTier3Change}
                />

                {rewardOffer &&
                  rewardOffer.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant='h6'
                            style={{ marginBottom: "10px" }}
                          >
                            Reward offer # {index + 1}:
                          </Typography>

                          <div>
                            <IconButton
                              aria-label='addOffer'
                              color='primary'
                              onClick={addRewardOffer}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              aria-label='delete'
                              edge='end'
                              color='secondary'
                              onClick={() => removeRewardOffer(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>

                        <TextField
                          style={classes.textfieldPadding}
                          id={`offer${index}-description`}
                          label='Description'
                          fullWidth={true}
                          variant='outlined'
                          name='description'
                          value={item.description}
                          onChange={(e) => handleOfferChange(e, index)}
                        />

                        <TextField
                          style={classes.textfieldPadding}
                          id='offer-tag'
                          label='Tag Name'
                          fullWidth={true}
                          variant='outlined'
                          value={item.tag}
                          name='tag'
                          onChange={(e) => handleOfferChange(e, index)}
                        />

                        <TextField
                          style={classes.textfieldPadding}
                          id='offer-link'
                          label='Link'
                          fullWidth={true}
                          variant='outlined'
                          value={item.link}
                          name='link'
                          onChange={(e) => handleOfferChange(e, index)}
                        />
                      </React.Fragment>
                    );
                  })}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant='h6'>Key Features:</Typography>
                  <IconButton
                    aria-label='addKeyFeature'
                    color='primary'
                    onClick={addKeyFeature}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                </div>

                {keyFeatures &&
                  keyFeatures.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TextField
                          style={classes.textfieldPadding}
                          id={`h`}
                          fullWidth={true}
                          variant='outlined'
                          value={item}
                          onChange={(e) => handleKeyFeatureChange(e, index)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='delete'
                                  edge='end'
                                  color='secondary'
                                  onClick={() => removeKeyFeature(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </React.Fragment>
                    );
                  })}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant='h6'>Commision Fees:</Typography>
                  <IconButton
                    aria-label='add'
                    color='primary'
                    onClick={addCommisionFee}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                </div>

                {commisionFee &&
                  commisionFee.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TextField
                          style={classes.textfieldPadding}
                          id={`h`}
                          fullWidth={true}
                          variant='outlined'
                          value={item}
                          onChange={(e) => handleCommisionFeeChange(e, index)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='deleteCommisionFee'
                                  edge='end'
                                  color='secondary'
                                  onClick={() => removeCommisionFee(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant='h6'>Additional Details:</Typography>
                  <IconButton
                    aria-label='add'
                    color='primary'
                    onClick={addAdditionalDetail}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                </div>

                {additionalDetail &&
                  additionalDetail.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TextField
                          style={classes.textfieldPadding}
                          id={`h`}
                          fullWidth={true}
                          variant='outlined'
                          value={item}
                          onChange={(e) =>
                            handleAdditionalDetailChange(e, index)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='deleteAdditionalDetail'
                                  edge='end'
                                  color='secondary'
                                  onClick={() => removeAdditionalDetail(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              style={{
                marginTop: "2%",
                marginBottom: "2%",
              }}
              onClick={handleSubmit}
            >
              Finish
            </Button>{" "}
            {newInvestment && <DoneIcon />}
          </form>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default CreateInvestment;
