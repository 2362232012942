export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_TAGS_SUCCESS = "GET_POSTS_TAGS_SUCCESS";
export const GET_POSTS_FAIL = "GET_POSTS_FAIL";

export const POST_DETAILS_REQUEST = "POST_DETAILS_REQUEST";
export const POST_DETAILS_SUCCESS = "POST_DETAILS_SUCCESS";
export const POST_DETAILS_FAIL = "POST_DETAILS_FAIL";

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAIL = "CREATE_POST_FAIL";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAIL = "DELETE_POST_FAIL";

export const CLEAR_POST_DELETE_DATA = "CLEAR_POST_DELETE_DATA";
export const CLEAR_POST_CREATE_DATA = "CLEAR_POST_CREATE_DATA";
export const CLEAR_POST_UPDATE_DATA = "CLEAR_POST_UPDATE_DATA";

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAIL = "UPDATE_POST_FAIL";
