import {
  SAVING_DETAILS_FAIL,
  SAVING_DETAILS_REQUEST,
  SAVING_DETAILS_SUCCESS,
  GET_SAVINGS_FAIL,
  GET_SAVINGS_REQUEST,
  GET_SAVINGS_SUCCESS,
  DELETE_SAVING_REQUEST,
  DELETE_SAVING_SUCCESS,
  DELETE_SAVING_FAIL,
  CLEAR_SAVING_DELETE_DATA,
  UPDATE_SAVING_REQUEST,
  UPDATE_SAVING_SUCCESS,
  UPDATE_SAVING_FAIL,
  CLEAR_SAVING_UPDATE_DATA,
  CREATE_SAVING_REQUEST,
  CREATE_SAVING_SUCCESS,
  CREATE_SAVING_FAIL,
  CLEAR_SAVING_CREATE_DATA,
} from "../constants/savingConstants";

export const getSavingsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SAVINGS_REQUEST:
      return { loading: true };
    case GET_SAVINGS_SUCCESS:
      return { loading: false, savings: action.payload };
    case GET_SAVINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const savingDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVING_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SAVING_DETAILS_SUCCESS:
      return { loading: false, savingDetails: action.payload };
    case SAVING_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSavingReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SAVING_REQUEST:
      return { loading: true, ...state };
    case DELETE_SAVING_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_SAVING_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_SAVING_DELETE_DATA:
      return { loading: null, message: null, error: null };
    default:
      return state;
  }
};

export const updateSavingReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SAVING_REQUEST:
      return { loading: true, ...state };
    case UPDATE_SAVING_SUCCESS:
      return { loading: false, updatedSaving: action.payload };
    case UPDATE_SAVING_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_SAVING_UPDATE_DATA:
      return { loading: null, updatedSaving: null, error: null };
    default:
      return state;
  }
};

export const createSavingReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SAVING_REQUEST:
      return { loading: true, ...state };
    case CREATE_SAVING_SUCCESS:
      return { loading: false, newSaving: action.payload };
    case CREATE_SAVING_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_SAVING_CREATE_DATA:
      return { loading: null, newSaving: null, error: null };
    default:
      return state;
  }
};
