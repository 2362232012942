import {
  INVESTMENT_DETAILS_FAIL,
  INVESTMENT_DETAILS_REQUEST,
  INVESTMENT_DETAILS_SUCCESS,
  GET_INVESTMENTS_FAIL,
  GET_INVESTMENTS_REQUEST,
  GET_INVESTMENTS_SUCCESS,
  DELETE_INVESTMENT_REQUEST,
  DELETE_INVESTMENT_SUCCESS,
  DELETE_INVESTMENT_FAIL,
  CLEAR_INVESTMENT_DELETE_DATA,
  UPDATE_INVESTMENT_REQUEST,
  UPDATE_INVESTMENT_SUCCESS,
  UPDATE_INVESTMENT_FAIL,
  CLEAR_INVESTMENT_UPDATE_DATA,
  CREATE_INVESTMENT_REQUEST,
  CREATE_INVESTMENT_SUCCESS,
  CREATE_INVESTMENT_FAIL,
  CLEAR_INVESTMENT_CREATE_DATA,
} from "../constants/investmentConstants";

export const getInvestmentsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVESTMENTS_REQUEST:
      return { loading: true };
    case GET_INVESTMENTS_SUCCESS:
      return { loading: false, investments: action.payload };
    case GET_INVESTMENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const investmentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case INVESTMENT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case INVESTMENT_DETAILS_SUCCESS:
      return { loading: false, investmentDetails: action.payload };
    case INVESTMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteInvestmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_INVESTMENT_REQUEST:
      return { loading: true, ...state };
    case DELETE_INVESTMENT_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_INVESTMENT_DELETE_DATA:
      return { loading: null, message: null, error: null };
    default:
      return state;
  }
};

export const updateInvestmentReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_INVESTMENT_REQUEST:
      return { loading: true, ...state };
    case UPDATE_INVESTMENT_SUCCESS:
      return { loading: false, updatedInvestment: action.payload };
    case UPDATE_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_INVESTMENT_UPDATE_DATA:
      return { loading: null, updatedInvestment: null, error: null };
    default:
      return state;
  }
};

export const createInvestmentReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_INVESTMENT_REQUEST:
      return { loading: true, ...state };
    case CREATE_INVESTMENT_SUCCESS:
      return { loading: false, newInvestment: action.payload };
    case CREATE_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_INVESTMENT_CREATE_DATA:
      return { loading: null, newInvestment: null, error: null };
    default:
      return state;
  }
};
