import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

const Header = ({ PrimaryHeading, SecondaryHeading }) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <Container maxWidth='md'>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "70%",
            marginTop: "80px",
          },
        }}
      >
        <Paper elevation={3}>
          <ThemeProvider theme={theme}>
            <Typography
              variant='h5'
              align='center'
              style={{ paddingTop: "10px", margin: "5px" }}
              gutterBottom
            >
              {PrimaryHeading}
            </Typography>
            <Typography
              variant='h6'
              color='initial'
              align='center'
              style={{ paddingBottom: "10px", margin: "5px" }}
              gutterBottom
            >
              {SecondaryHeading}
            </Typography>
          </ThemeProvider>
        </Paper>
      </Box>
    </Container>
  );
};

export default Header;
