import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Zero from "./components/Zero";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Cards from "./pages/Cards";
import CardDetailsPage from "./pages/CardDetailsPage";
import UpdateCardPage from "./pages/editscreens/UpdateCardPage";
import CreateCreditCard from "./pages/createscreens/CreateCreditCard";
import Investments from "./pages/Investments";
import InvestmentDetailsPage from "./pages/InvestmentDetailsPage";
import UpdateInvestmentPage from "./pages/editscreens/UpdateInvestment";
import CreateInvestment from "./pages/createscreens/CreateInvestment";
import Insurances from "./pages/Insurances";
import InsuranceDetailsPage from "./pages/InsuranceDetailsPage";
import UpdateInsurancePage from "./pages/editscreens/UpdateInsurance";
import CreateInsurance from "./pages/createscreens/CreateInsurance";
import Loans from "./pages/Loans";
import UpdateLoanPage from "./pages/editscreens/UpdateLoanPage";
import CreateLoan from "./pages/createscreens/CreateLoan";
import Blog from "./blog/Blog";
import SuggestRewards from "./pages/SuggestRewards";
import LoginScreen from "./pages/LoginScreen";
import PostDetailsPage from "./blog/PostDetailsPage";
import CreateNewPost from "./blog/admin-area/CreateNewPost";
import UpdatePostPage from "./blog/admin-area/UpdatePostPage";
import LoanDetailsPage from "./pages/LoanDetailsPage";
import Savings from "./pages/Savings";
import SavingDetailsPage from "./pages/SavingDetailsPage";
import UpdateSavingPage from "./pages/editscreens/UpdateSavingPage";
import CreateSaving from "./pages/createscreens/CreateSaving";
import SignUp from "./pages/UserRegisterPage";
import ScrollToTop from "./ScrollToTop";
import "./App.css";


function App() {
  return (
    <Router>
      <NavBar />
      <main style={{ minHeight: "60vh" }}>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/login' component={LoginScreen} />
          <Route exact path='/cards' component={Cards} />
          <Route exact path='/cards/:id' component={CardDetailsPage} />
          <Route exact path='/update-creditcard' component={UpdateCardPage} />
          <Route exact path='/new-card' component={CreateCreditCard} />
          <Route exact path='/insurances' component={Investments} />
          <Route
            exact
            path='/insurances/:id'
            component={InvestmentDetailsPage}
          />
          <Route
            exact
            path='/update-insurance'
            component={UpdateInvestmentPage}
          />
          <Route exact path='/new-insurance' component={CreateInvestment} />

          <Route exact path='/investments' component={Insurances} />
          <Route
            exact
            path='/investments/:id'
            component={InsuranceDetailsPage}
          />
          <Route
            exact
            path='/update-investment'
            component={UpdateInsurancePage}
          />
          <Route exact path='/new-investment' component={CreateInsurance} />

          <Route exact path='/loans' component={Loans} />
          <Route exact path='/loans/:id' component={LoanDetailsPage} />
          <Route exact path='/update-loan' component={UpdateLoanPage} />
          <Route exact path='/new-loan' component={CreateLoan} />

          <Route exact path='/savings' component={Savings} />
          <Route exact path='/savings/:id' component={SavingDetailsPage} />
          <Route exact path='/update-saving' component={UpdateSavingPage} />
          <Route exact path='/new-saving' component={CreateSaving} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/posts/:id' component={PostDetailsPage} />
          <Route exact path='/blog/new-post' component={CreateNewPost} />
          <Route exact path='/blog/update' component={UpdatePostPage} />
          <Route exact path='/suggest-rewards' component={SuggestRewards} />
          <Route exact path='/Signup' component={SignUp} />
        </Switch>
      </main>

      <Zero />
      <Footer />
    </Router>
  );
}

export default App;
