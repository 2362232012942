export const GET_SAVINGS_REQUEST = "GET_SAVINGS_REQUEST";
export const GET_SAVINGS_SUCCESS = "GET_SAVINGS_SUCCESS";
export const GET_SAVINGS_FAIL = "GET_SAVINGS_FAIL";

export const SAVING_DETAILS_REQUEST = "SAVING_DETAILS_REQUEST";
export const SAVING_DETAILS_SUCCESS = "SAVING_DETAILS_SUCCESS";
export const SAVING_DETAILS_FAIL = "SAVING_DETAILS_FAIL";

export const DELETE_SAVING_REQUEST = "DELETE_SAVING_REQUEST ";
export const DELETE_SAVING_SUCCESS = "DELETE_SAVING_SUCCESS ";
export const DELETE_SAVING_FAIL = "DELETE_SAVING_FAIL ";

export const CLEAR_SAVING_DELETE_DATA = "CLEAR_SAVING_DELETE_DATA ";
export const CLEAR_SAVING_CREATE_DATA = "CLEAR_SAVING_CREATE_DATA";
export const CLEAR_SAVING_UPDATE_DATA = "CLEAR_SAVING_UPDATE_DATA";

export const UPDATE_SAVING_REQUEST = "UPDATE_SAVING_REQUEST";
export const UPDATE_SAVING_SUCCESS = "UPDATE_SAVING_SUCCESS";
export const UPDATE_SAVING_FAIL = "UPDATE_SAVING_FAIL";

export const CREATE_SAVING_REQUEST = "CREATE_SAVING_REQUEST";
export const CREATE_SAVING_SUCCESS = "CREATE_SAVING_SUCCESS";
export const CREATE_SAVING_FAIL = "CREATE_SAVING_FAIL";
