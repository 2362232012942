import {
  INSURANCE_DETAILS_FAIL,
  INSURANCE_DETAILS_REQUEST,
  INSURANCE_DETAILS_SUCCESS,
  GET_INSURANCES_FAIL,
  GET_INSURANCES_REQUEST,
  GET_INSURANCES_SUCCESS,
  DELETE_INSURANCE_REQUEST,
  DELETE_INSURANCE_SUCCESS,
  DELETE_INSURANCE_FAIL,
  CLEAR_INSURANCE_DELETE_DATA,
  UPDATE_INSURANCE_REQUEST,
  UPDATE_INSURANCE_SUCCESS,
  UPDATE_INSURANCE_FAIL,
  CLEAR_INSURANCE_UPDATE_DATA,
  CREATE_INSURANCE_REQUEST,
  CREATE_INSURANCE_SUCCESS,
  CREATE_INSURANCE_FAIL,
  CLEAR_INSURANCE_CREATE_DATA,
} from "../constants/insuranceConstants";

export const getInsurancesListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INSURANCES_REQUEST:
      return { loading: true };
    case GET_INSURANCES_SUCCESS:
      return { loading: false, insurances: action.payload };
    case GET_INSURANCES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const insuranceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case INSURANCE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case INSURANCE_DETAILS_SUCCESS:
      return { loading: false, insuranceDetails: action.payload };
    case INSURANCE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_INSURANCE_REQUEST:
      return { loading: true, ...state };
    case DELETE_INSURANCE_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_INSURANCE_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_INSURANCE_DELETE_DATA:
      return { loading: null, message: null, error: null };
    default:
      return state;
  }
};

export const updateInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_INSURANCE_REQUEST:
      return { loading: true, ...state };
    case UPDATE_INSURANCE_SUCCESS:
      return { loading: false, updatedInsurance: action.payload };
    case UPDATE_INSURANCE_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_INSURANCE_UPDATE_DATA:
      return { loading: null, updatedInsurance: null, error: null };
    default:
      return state;
  }
};

export const createInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_INSURANCE_REQUEST:
      return { loading: true, ...state };
    case CREATE_INSURANCE_SUCCESS:
      return { loading: false, newInsurance: action.payload };
    case CREATE_INSURANCE_FAIL:
      return { loading: false, error: action.payload };
    case CLEAR_INSURANCE_CREATE_DATA:
      return { loading: null, newInsurance: null, error: null };
    default:
      return state;
  }
};
