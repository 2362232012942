import React from "react";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const Footer = () => {
  return (
    <>
      <footer className='w3-container w3-padding-64 w3-center w3-opacity'>
        <div className='w3-xlarge w3-padding-32'>
          <a href='https://www.facebook.com/MoneySaver-109707054780042'>
            <FacebookIcon color='primary' />
          </a>
          <a href='https://www.instagram.com/backalleytrader/'>
            <InstagramIcon color='primary' />
          </a>
        </div>
        <p>
          Advertise With Us:
          <a href='mailto:info@moneysaver.studio'> info@moneysaver.studio </a>
        </p>
      </footer>
    </>
  );
};

export default Footer;
