import React from "react";
import Header from "../components/Header";
import Container from "@mui/material/Container";

const SuggestRewards = () => {
  return (
    <>
      <Header
        PrimaryHeading={" Looking for other rewards?"}
        SecondaryHeading={
          "Make your voice heard! We'll notify you when there's a match!"
        }
      />
      <Container
        maxWidth='md'
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        <iframe
          title='Google Form'
          src='https://docs.google.com/forms/d/e/1FAIpQLSe8D3iNtUmVxStpJ2Id-Bwiau6jXpunGHWXefxFQDW0pIApig/viewform?embedded=true'
          width='100%'
          height='708'
          frameBorder='0'
          marginHeight='0'
          marginWidth='0'
        >
          Loading...
        </iframe>
      </Container>
    </>
  );
};

export default SuggestRewards;
