import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { updatePost, clearPostUpdateData } from "../actions/blogActions";
import { Link } from "react-router-dom";

import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const UpdatePostPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPostUpdateData());
  }, [dispatch]);

  const {
    loading: postUpdatingLoading,
    error: postUpdatingError,
    updatedPost,
  } = useSelector((state) => state.updatedBlogPost);

  const { loading, error, post } = useSelector((state) => state.postDetails);

  const content = post[0];
  const [title, setTitle] = useState(content.title);
  const [slug, setSlug] = useState(content.slug);
  const [priority, setPriority] = useState(content.priority ? content.priority : 0);
  const [description, setDescription] = useState(content.description);
  const [tags, setTags] = useState(content.tags);
  const [author, setAuthor] = useState(content.author);
  const [richTextDescription] = useState(content.richTextDescription);
  const [uploadedImages, setUploadedImages] = useState([]);
  
  const [updatedRichTextDescription, setUpdatedRichTextDescription] =
    useState("");
  const [files, setFiles] = useState(null);
  const [previewImage, setPreviewImage] = useState(content.image);

  const handleSubmit = (e) => {
    e.preventDefault();
    const stringEditor = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    dispatch(
      updatePost({
        id: content._id,
        title,
        slug,
        priority,
        description,
        tags,
        author,
        richTextDescription: editorState
          ? stringEditor
          : richTextDescription,
        files,
        uploadedImages,
      })
    );
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };
  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleTagsChange = (e) => {
    setTags(e.target.value.split(","));
  };
  const handleAuthorChange = (e) => {
    setAuthor(e.target.value);
  };
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    }

    setFiles(file);
  }

  const [editorState, setEditorState] = useState(
    richTextDescription ? EditorState.createWithContent(
      convertFromRaw(JSON.parse(richTextDescription))
    ) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState, event) => {
    if (event) {
      event.preventDefault();
    }
    setEditorState(editorState);
  };

  const wrapperStyle = {
    border: "1px solid #969696",
  };

  const editorStyle = {
    height: "auto",
    minHeight: "40rem",
    padding: "1rem",
  };

  const classes = {
    textfieldPadding: {
      // marginLeft: "30px",
      marginBottom: "15px",
    },
  };

  const uploadImageCallBack = async (file) => {
    let uploadedImage = uploadedImages;
    const imageObject = {
        file: file,
        localSrc: URL.createObjectURL(file),
    }

    uploadedImage.push(imageObject);
    setUploadedImages(uploadedImage);
    return new Promise((resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <>
      <Container component='main' maxWidth='lg' style={{ marginTop: "80px" }}>
        <br />
        <Typography align='center' variant='h4'>
          Edit blog post
        </Typography>
        <br />
        {error && (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {error}
          </div>
        )}
        {postUpdatingError && (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {postUpdatingError}
          </div>
        )}

        {loading && <LinearProgress />}
        {postUpdatingLoading && <LinearProgress />}
        {updatedPost && (
          <div
            style={{
              padding: "20px",
              backgroundColor: "green",
              color: "white",
              marginBottom: "15px",
            }}
          >
            Post updated successfully. Go to{" "}
            <Link to='/blog'>
              {" "}
              <b>blog page. </b>
            </Link>
          </div>
        )}
        <br />
        <form noValidate autoComplete='on'>
          <Grid container spacing={3}>
            <Grid item xs={24} md={12}>
              <label htmlFor="contained-button-file">
                <input style={{display: "none"}} accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => handleUploadImage(e)} />
                <Button variant="contained" component="span" style={{marginBottom: "15px"}}>
                  Upload Image
                </Button>
              </label>

              {
                previewImage && (
                  <div style={{marginBottom: "15px"}}>
                    <img style={{maxWidth: "360px"}} src={previewImage} alt="upload"/>
                  </div>
                )
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='title'
                label='Title'
                fullWidth={true}
                variant='standard'
                value={title}
                style={classes.textfieldPadding}
                // inputProps={{ maxLength: 50 }}
                onChange={handleTitleChange}
              />
              <TextField
                id='slug'
                label='Slug Url'
                fullWidth={true}
                variant='standard'
                value={slug}
                style={classes.textfieldPadding}
                // inputProps={{ maxLength: 50 }}
                onChange={handleSlugChange}
              />
              <TextField
                style={classes.textfieldPadding}
                id='priority'
                label='Priority'
                type="number"
                fullWidth={true}
                variant='standard'
                value={priority}
                onChange={handlePriorityChange}
              />
              <TextField
                variant='standard'
                fullWidth
                placeholder='Short Description'
                // style={{ width: 565 }}
                style={classes.textfieldPadding}
                value={description}
                onChange={handleDescriptionChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                variant='outlined'
                id='image'
                value={image}
                label='Image URL'
                fullWidth={true}
                onChange={handleImageURLChange}
              /> */}
              <TextField
                variant='standard'
                id='author'
                value={author}
                label='Author'
                fullWidth={true}
                style={classes.textfieldPadding}
                onChange={handleAuthorChange}
              />
              <TextField
                variant='standard'
                id='tags'
                label='Tags'
                value={tags.toString()}
                placeholder='Input comma separated values. eg; Finance,Investment'
                fullWidth={true}
                style={classes.textfieldPadding}
                onChange={handleTagsChange}
              />
            </Grid>
          </Grid>
          <Typography variant=''>Detailed description:</Typography>
          {/* <RichTextEditor /> */}
          {/*  */}
          <div>
            <Editor
              editorState={editorState}
              // initialEditorState={editorState}
              // initialEditorState={JSON.parse(richTextDescription)}
              wrapperClassName='wrapper-class'
              wrapperStyle={wrapperStyle}
              editorStyle={editorStyle}
              editorClassName='demo-editor'
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "textAlign",
                  "list",
                  "history",
                  "colorPicker",
                  "image"
                ],
                inline: {
                  options: [
                    "italic",
                    "bold",
                    "underline",
                    "strikethrough",
                    "monospace",
                    "superscript",
                    "subscript",
                  ],
                  bold: { className: "demo-option-custom" },
                  italic: { className: "demo-option-custom" },
                  underline: { className: "demo-option-custom" },
                  strikethrough: { className: "demo-option-custom" },
                  monospace: { className: "demo-option-custom" },
                  superscript: { className: "demo-option-custom" },
                  subscript: { className: "demo-option-custom" },
                },
                blockType: {
                  className: "demo-option-custom-wide",
                  dropdownClassName: "demo-dropdown-custom",
                },
                list: {
                  inDropdown: false,
                  className: "demo-option-custom-wide",
                  dropdownClassName: "demo-dropdown-custom",
                  options: ["unordered", "ordered", "indent", "outdent"],
                  unordered: { className: "demo-option-custom" },
                  ordered: { className: "demo-option-custom" },
                  indent: { className: "demo-option-custom" },
                  outdent: { className: "demo-option-custom" },
                },
                fontSize: { className: "demo-option-custom-medium" },
                image: {
                  urlEnabled: false,
                  uploadEnabled: true,
                  uploadCallback: uploadImageCallBack,
                  alignmentEnabled: true,
                  defaultSize: {
                      height: 'auto',
                      width: 'auto',
                  }
                }
              }}
            />
          </div>
          {/*  */}
          <Button
            type='submit'
            // fullWidth
            variant='contained'
            color='primary'
            style={{
              marginTop: "2%",
              marginBottom: "2%",
            }}
            onClick={handleSubmit}
          >
            Update Post
          </Button>{" "}
          {updatedPost && <DoneIcon />}
        </form>
      </Container>
    </>
  );
};

export default UpdatePostPage;
