export const GET_INVESTMENTS_REQUEST = "GET_INVESTMENTS_REQUEST";
export const GET_INVESTMENTS_SUCCESS = "GET_INVESTMENTS_SUCCESS";
export const GET_INVESTMENTS_FAIL = "GET_INVESTMENTS_FAIL";

export const INVESTMENT_DETAILS_REQUEST = "INVESTMENT_DETAILS_REQUEST";
export const INVESTMENT_DETAILS_SUCCESS = "INVESTMENT_DETAILS_SUCCESS";
export const INVESTMENT_DETAILS_FAIL = "INVESTMENT_DETAILS_FAIL";

export const DELETE_INVESTMENT_REQUEST = "DELETE_INVESTMENT_REQUEST ";
export const DELETE_INVESTMENT_SUCCESS = "DELETE_INVESTMENT_SUCCESS ";
export const DELETE_INVESTMENT_FAIL = "DELETE_INVESTMENT_FAIL ";

export const CLEAR_INVESTMENT_DELETE_DATA = "CLEAR_INVESTMENT_DELETE_DATA ";
export const CLEAR_INVESTMENT_CREATE_DATA = "CLEAR_INVESTMENT_CREATE_DATA";
export const CLEAR_INVESTMENT_UPDATE_DATA = "CLEAR_INVESTMENT_UPDATE_DATA";

export const UPDATE_INVESTMENT_REQUEST = "UPDATE_INVESTMENT_REQUEST";
export const UPDATE_INVESTMENT_SUCCESS = "UPDATE_INVESTMENT_SUCCESS";
export const UPDATE_INVESTMENT_FAIL = "UPDATE_INVESTMENT_FAIL";

export const CREATE_INVESTMENT_REQUEST = "CREATE_INVESTMENT_REQUEST";
export const CREATE_INVESTMENT_SUCCESS = "CREATE_INVESTMENT_SUCCESS";
export const CREATE_INVESTMENT_FAIL = "CREATE_INVESTMENT_FAIL";
