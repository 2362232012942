export const GET_INSURANCES_REQUEST = "GET_INSURANCES_REQUEST";
export const GET_INSURANCES_SUCCESS = "GET_INSURANCES_SUCCESS";
export const GET_INSURANCES_FAIL = "GET_INSURANCES_FAIL";

export const INSURANCE_DETAILS_REQUEST = "INSURANCE_DETAILS_REQUEST";
export const INSURANCE_DETAILS_SUCCESS = "INSURANCE_DETAILS_SUCCESS";
export const INSURANCE_DETAILS_FAIL = "INSURANCE_DETAILS_FAIL";

export const DELETE_INSURANCE_REQUEST = "DELETE_INSURANCE_REQUEST ";
export const DELETE_INSURANCE_SUCCESS = "DELETE_INSURANCE_SUCCESS ";
export const DELETE_INSURANCE_FAIL = "DELETE_INSURANCE_FAIL ";

export const CLEAR_INSURANCE_DELETE_DATA = "CLEAR_INSURANCE_DELETE_DATA ";
export const CLEAR_INSURANCE_CREATE_DATA = "CLEAR_INSURANCE_CREATE_DATA";
export const CLEAR_INSURANCE_UPDATE_DATA = "CLEAR_INSURANCE_UPDATE_DATA";

export const UPDATE_INSURANCE_REQUEST = "UPDATE_INSURANCE_REQUEST";
export const UPDATE_INSURANCE_SUCCESS = "UPDATE_INSURANCE_SUCCESS";
export const UPDATE_INSURANCE_FAIL = "UPDATE_INSURANCE_FAIL";

export const CREATE_INSURANCE_REQUEST = "CREATE_INSURANCE_REQUEST";
export const CREATE_INSURANCE_SUCCESS = "CREATE_INSURANCE_SUCCESS";
export const CREATE_INSURANCE_FAIL = "CREATE_INSURANCE_FAIL";
