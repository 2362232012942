import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  getPostsListReducer,
  postDetailsReducer,
  createPostReducer,
  deletePostReducer,
  updatePostReducer,
} from "../blog/reducers/blogReducer";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
} from "../reducers/userReducer";
import {
  getCardsListReducer,
  cardDetailsReducer,
  deleteCardReducer,
  updateCardReducer,
  createCardReducer,
} from "../reducers/cardReducer";
import {
  getInvestmentsListReducer,
  investmentDetailsReducer,
  deleteInvestmentReducer,
  updateInvestmentReducer,
  createInvestmentReducer,
} from "../reducers/investmentsReducer";
import {
  getInsurancesListReducer,
  insuranceDetailsReducer,
  deleteInsuranceReducer,
  updateInsuranceReducer,
  createInsuranceReducer,
} from "../reducers/insuranceReducer";
import {
  getLoansListReducer,
  loanDetailsReducer,
  deleteLoanReducer,
  updateLoanReducer,
  createLoanReducer,
} from "../reducers/loanReducer";
import {
  getSavingsListReducer,
  savingDetailsReducer,
  deleteSavingReducer,
  updateSavingReducer,
  createSavingReducer,
} from "../reducers/savingReducer";

const reducer = combineReducers({
  blogPosts: getPostsListReducer,
  postDetails: postDetailsReducer,
  newPost: createPostReducer,
  deleteBlogPost: deletePostReducer,
  updatedBlogPost: updatePostReducer,
  cardsList: getCardsListReducer,
  cardDetails: cardDetailsReducer,
  updatedCard: updateCardReducer,
  newCard: createCardReducer,
  deleteCreditCard: deleteCardReducer,
  investmentsList: getInvestmentsListReducer,
  investmentDetails: investmentDetailsReducer,
  updatedInvestment: updateInvestmentReducer,
  newInvestment: createInvestmentReducer,
  deleteInvestment: deleteInvestmentReducer,
  insuranceList: getInsurancesListReducer,
  insuranceDetails: insuranceDetailsReducer,
  updatedInsurance: updateInsuranceReducer,
  newInsurance: createInsuranceReducer,
  deleteInsurance: deleteInsuranceReducer,
  loansList: getLoansListReducer,
  loanDetails: loanDetailsReducer,
  updatedLoan: updateLoanReducer,
  newLoan: createLoanReducer,
  deleteLoan: deleteLoanReducer,
  savingsList: getSavingsListReducer,
  savingDetails: savingDetailsReducer,
  updatedSaving: updateSavingReducer,
  newSaving: createSavingReducer,
  deleteSaving: deleteSavingReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
