export const GET_LOANS_REQUEST = "GET_LOANS_REQUEST";
export const GET_LOANS_SUCCESS = "GET_LOANS_SUCCESS";
export const GET_LOANS_FAIL = "GET_LOANS_FAIL";

export const LOAN_DETAILS_REQUEST = "LOAN_DETAILS_REQUEST";
export const LOAN_DETAILS_SUCCESS = "LOAN_DETAILS_SUCCESS";
export const LOAN_DETAILS_FAIL = "LOAN_DETAILS_FAIL";

export const DELETE_LOAN_REQUEST = "DELETE_LOAN_REQUEST ";
export const DELETE_LOAN_SUCCESS = "DELETE_LOAN_SUCCESS ";
export const DELETE_LOAN_FAIL = "DELETE_LOAN_FAIL ";

export const CLEAR_LOAN_DELETE_DATA = "CLEAR_LOAN_DELETE_DATA ";
export const CLEAR_LOAN_CREATE_DATA = "CLEAR_LOAN_CREATE_DATA";
export const CLEAR_LOAN_UPDATE_DATA = "CLEAR_LOAN_UPDATE_DATA";

export const UPDATE_LOAN_REQUEST = "UPDATE_LOAN_REQUEST";
export const UPDATE_LOAN_SUCCESS = "UPDATE_LOAN_SUCCESS";
export const UPDATE_LOAN_FAIL = "UPDATE_LOAN_FAIL";

export const CREATE_LOAN_REQUEST = "CREATE_LOAN_REQUEST";
export const CREATE_LOAN_SUCCESS = "CREATE_LOAN_SUCCESS";
export const CREATE_LOAN_FAIL = "CREATE_LOAN_FAIL";
