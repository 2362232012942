import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import { Link, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const NavBar = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  function toggleMenuHandler() {
    var x = document.getElementById("navDemo");

    if (x.className.indexOf("w3-show") === -1) {
      x.className += " w3-show";
    } else {
      x.className = x.className.replace(" w3-show", "");
    }
  }

  const [open, setOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [displayBtnLogin, setDisplayBtnLogin] = useState("");
  const [displayBtnLogout, setDisplayBtnLogout] = useState("");
  const [displayBtnNewPost, setDisplayBtnNewPost] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      setDisplayBtnLogin("none");
      setDisplayBtnLogout("block");
      setDisplayBtnNewPost("block");
    } else if (userInfo) {
      setDisplayBtnLogin("none");
      setDisplayBtnLogout("block");
      setDisplayBtnNewPost("none");
    } else {
      setDisplayBtnLogin("block");
      setDisplayBtnLogout("none");
      setDisplayBtnNewPost("none");
    }
  }, [userInfo]);

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity='success' sx={{ width: "100%" }} onClose={handleClose}>
          Logged out successfully!
        </Alert>
      </Snackbar>

      <div className='w3-top' style={{ zIndex: "5" }}>
        <div className='w3-bar w3-card w3-white w3-left-align w3-large'>
          <div
            className='
                    w3-bar-item
                    w3-button
                    w3-hide-large
                    w3-right
                    w3-padding-large
                    w3-hover-white
                    w3-large
                    w3-white
                    '
            onClick={toggleMenuHandler}
            title='Toggle Navigation Menu'
          >
            <IconButton>
              <MenuIcon />
            </IconButton>
          </div>
          <Link to='/' className='w3-bar-item w3-padding-large w3-white'>
            <b>
              <img
                className='header-logo'
                src='/images/MSLogo.jpg'
                alt='Money Saver Credit Card Loans Investment Savings'
              />
            </b>
          </Link>

          <Link
            to='/cards'
            className='w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Cards
          </Link>
          <Link
            to='/investments'
            className='w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Investments
          </Link>
          <Link
            to='/insurances'
            className='w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Insurances
          </Link>
          <Link
            to='/savings'
            className='w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Savings
          </Link>
          <Link
            to='/loans'
            className='w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Loans
          </Link>
          <Link
            to='/blog'
            className='w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Blog
          </Link>
          <Link
            to='/suggest-rewards'
            className='  w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Suggest Rewards
          </Link>
          <Link
            to='/signup'
            style={{ float: "right", display: `${displayBtnLogin}` }}
            className='  w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Sign Up
          </Link>
          <Link
            to='/login'
            style={{ float: "right", display: `${displayBtnLogin}` }}
            className='  w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Login
          </Link>

          <Link
            to=''
            style={{ float: "right", display: `${displayBtnLogout}` }}
            className='  w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
            onClick={logoutHandler}
          >
            Logout
          </Link>

          <div
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className='w3-dropdown-hover w3-bar-item w3-button w3-hide-small w3-hide-medium w3-padding-large w3-hover-white'
          >
            Create New
            <div className='w3-dropdown-content w3-bar-block w3-card-4'>
              <Link to={"/blog/new-post"} className='w3-bar-item w3-button'>
                Blog Post
              </Link>
              <Link to={"/new-card"} className='w3-bar-item w3-button'>
                Credit Card
              </Link>
              <Link to={"/new-investment"} className='w3-bar-item w3-button'>
                Investment
              </Link>
              <Link to={"/new-insurance"} className='w3-bar-item w3-button'>
                Insurance
              </Link>
              <Link to={"/new-saving"} className='w3-bar-item w3-button'>
                Saving
              </Link>
              <Link to={"/new-loan"} className='w3-bar-item w3-button'>
                Loan
              </Link>
            </div>
          </div>
        </div>

        {/* mobile view */}
        {/* mobile view */}
        {/* mobile view */}
        <div
          id='navDemo'
          className='w3-bar-block w3-white w3-hide w3-hide-large w3-large'
        >
          <Link
            to='/cards'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Cards
          </Link>
          <Link
            to='/investments'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Investments
          </Link>
          <Link
            to='/insurances'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Insurances
          </Link>
          <Link
            to='/savings'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Savings
          </Link>
          <Link
            to='/loans'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Loans
          </Link>
          <Link
            to='/blog'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Blog
          </Link>
          <Link
            to='/suggest-rewards'
            className='w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Suggest Reward
          </Link>

          <Link
            to='/login'
            style={{ float: "right", display: `${displayBtnLogin}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Login
          </Link>
          <Link
            to='/signup'
            style={{ float: "right", display: `${displayBtnLogin}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Sign Up
          </Link>

          <Link
            to={"/blog/new-post"}
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Create blog post
          </Link>

          <Link
            to={"/new-card"}
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Create Credit Card
          </Link>
          <Link
            to={"/new-investment"}
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Create Investment
          </Link>
          <Link
            to={"/new-insurance"}
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Create Insurance
          </Link>
          <Link
            to={"/new-saving"}
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Create Saving
          </Link>
          <Link
            to={"/new-loan"}
            style={{ float: "right", display: `${displayBtnNewPost}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={toggleMenuHandler}
          >
            Create Loan
          </Link>
          <Link
            to=''
            style={{ float: "right", display: `${displayBtnLogout}` }}
            className=' w3-bar-item w3-button w3-padding-large'
            onClick={(e) => {
              toggleMenuHandler();
              logoutHandler(e);
            }}
          >
            Logout
          </Link>
        </div>
      </div>
    </>
  );
};

export default NavBar;
