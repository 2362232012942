import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import "./loginstyles.css";

const SignIn = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [userInfo, history]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      <section class='w3l-workinghny-form'>
        <div class='workinghny-form-grid'>
          <div class='wrapper'>
            <div class='logo'>
              <h1>
                <div class='brand-logo' style={{ paddingTop: "50px" }}>
                  Sign In
                </div>
              </h1>
            </div>
            <div class='workinghny-block-grid'>
              <div class='workinghny-left-img align-end'>
                <img src='/images/2.png' class='img-responsive' alt='img' />
              </div>
              <div class='form-right-inf'>
                <div class='login-form-content'>
                  {error && <div class='alert'>{error}</div>}
                  <form class='signin-form' onSubmit={handleSubmit}>
                    <div class='one-frm'>
                      <label>Email</label>
                      <input
                        type='text'
                        name='Name'
                        placeholder='admin@example.com'
                        required='true'
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div class='one-frm'>
                      <label>Password</label>
                      <input
                        type='password'
                        name='Password'
                        placeholder='Enter your password'
                        required='true'
                        onChange={handlePasswordChange}
                      />
                    </div>
                    {loading && <div class='loader'></div>}
                    <button class='btn btn-style mt-3'>Sign In</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
