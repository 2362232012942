import React, { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getPostDetails,
  deletePost,
  clearPostDeleteData,
} from "./actions/blogActions";
import Header from "../components/Header";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import moment from "moment";

const PostDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostDetails(match.params.id));
  }, [dispatch, match]);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, error, post } = useSelector((state) => state.postDetails);
  const {
    loading: loadingPostDelete,
    error: errorPostDelete,
    message,
  } = useSelector((state) => state.deleteBlogPost);

  const handleDeletePostBtn = (id) => {
    dispatch(deletePost(id));
    setTimeout(() => {
      dispatch(clearPostDeleteData());
      history.push("/blog");
    }, 1500);
  };

  let theme = createTheme({
    palette: {
      secondary: {
        main: "#bf151e",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <>
      <Header
        PrimaryHeading={"Personal finance, wealth opinions and others "}
        SecondaryHeading={
          "Make the best out of your time with bite size friendly information!"
        }
      />

      <Container
        maxWidth='md'
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        {loading ? (
          <LinearProgress />
        ) : loadingPostDelete ? (
          <LinearProgress />
        ) : errorPostDelete ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {errorPostDelete + " Redirecting to posts page..."}
          </div>
        ) : error ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336" /* Red */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {error}
          </div>
        ) : message ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "green" /* Green */,
              color: "white",
              marginBottom: "15px",
            }}
          >
            {message + " Redirecting to posts page..."}
          </div>
        ) : (
          <>
            {post &&
              post.map((item) => {
                return (
                  <Card style={{ border: "1px solid #a1a1a1" }} key={item._id}>
                    <ThemeProvider theme={theme}>
                      {userInfo ? (
                        <div style={{ float: "right", padding: "5px" }}>
                          {userInfo.isAdmin ? (
                            <ButtonGroup
                              variant='outlined'
                              aria-label='outlined button group'
                            >
                              <Link to='/blog/update'>
                                <Button>Edit</Button>
                              </Link>
                              <Button
                                style={{marginLeft: "10px"}}
                                color='secondary'
                                onClick={() => {
                                  handleDeletePostBtn(item._id);
                                }}
                              >
                                Delete
                              </Button>
                            </ButtonGroup>
                          ) : null}
                        </div>
                      ) : null}
                      <CardHeader title={item.title} />

                      <CardMedia
                        style={{
                          [theme.breakpoints.up("sm")]: {
                            maxWidth: "50%",
                          },
                          maxWidth: "50%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        component='img'
                        title='citi'
                        image={item.image}
                      />

                      <div
                        style={{
                          margin: "30px 30px 30px 30px",
                        }}
                      >
                        {
                          item.richTextDescription && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: draftToHtml(
                                  JSON.parse(item.richTextDescription)
                                ),
                              }}
                            ></div>
                          )
                        }

                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{ marginTop: "10px" }}
                        >
                          Author: {item.author}
                        </Typography>
                        <Typography variant='subtitle2' color='initial'>
                          Last Updated: {moment(item.createdAt).format("DD/MMM/YYYY HH:mm:ss")}
                        </Typography>
                        <Typography variant='subtitle1' color='initial'>
                          Tags:{"  "}
                          {item.tags.map((tag) => {
                            return <Chip label={tag} key={tag} />;
                          })}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Card>
                );
              })}
          </>
        )}
      </Container>
    </>
  );
};

export default PostDetailsPage;
