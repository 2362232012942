import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Card, CardContent, CardMedia, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { getCardsList } from "../actions/cardActions";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const Cards = () => {
  const useStyles = makeStyles({
    tierDiv: {
      paddingTop: "5px",
      paddingBottom: "5px",
      height: "50px",
    },
    selectedCAT: {
      border: "2px solid #1976d2",
    },
    rewardOffer: {
      padding: "0.7%",
      boxShadow: "2px",
      borderRadius: "10px",
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: "#ffecb3",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentCategory, setCurrentCategory] = useState("All");

  const cardCategories = [
    "All",
    "American Express",
    "CIMB",
    "Citibank",
    "DBS",
    "HSBC",
    "OCBC",
    "POSB",
    "Standard Chartered",
    "UOB",
  ];

  useEffect(() => {
    dispatch(getCardsList());
  }, [dispatch]);

  const handleCategoryChange = (e) => {
    setCurrentCategory(e.target.innerText);
  };

  const { loading, error, cards } = useSelector((state) => state.cardsList);

  let filteredCardList = [];

  if (currentCategory === "All") {
    filteredCardList = cards;
  } else if (cards) {
    filteredCardList = cards.filter(
      (card) => card.category === currentCategory
    );
  }

  return (
    <>
      <Header
        PrimaryHeading={
          "All the Credit Cards and Welcome Rewards in One Place!"
        }
        SecondaryHeading={
          "Welcome Rewards Up for Grabs! Cashback, Miles or Rewards Credit Card, We Got You Covered!"
        }
      />

      <Container
        maxWidth='lg'
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {cardCategories.map((cat) => {
            return (
              <Chip
                style={{ margin: "5px 5px 25px 5px" }}
                className={cat === currentCategory ? classes.selectedCAT : null}
                label={cat}
                component='a'
                variant='outlined'
                clickable
                onClick={handleCategoryChange}
              />
            );
          })}
        </Box>

        {loading ? (
          <LinearProgress />
        ) : error ? (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f44336",
              color: "white",
              marginBottom: "15px",
            }}
          >
            {error}
          </div>
        ) : (
          <Grid Grid container spacing={4}>
            {filteredCardList &&
              filteredCardList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={item._id} >
                    <Card style={{ maxWidth: "350px" }} className="card-media">
                      <div
                        style={{
                          height: "50px",
                          marginBottom: "5px",
                          padding: "5px",
                          // paddingBottom: "5px",
                        }}
                      >
                        <Typography
                          fontSize='18px'
                          style={{ fontWeight: 600 }}
                          color='inherit'
                          align='center'
                        >
                          {item.title}
                        </Typography>
                      </div>
                      <CardMedia
                        style={{
                          maxWidth: "95%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "15px",
                        }}
                        component='img'
                        title={item.title}
                        image={item.image}
                      />
                      <br />
                      <Link to={`/cards/${item.slug ? item.slug : item._id}`}>
                        <Button variant='contained' fullWidth color='primary'>
                          See Details
                        </Button>
                      </Link>
                      <CardContent
                        style={{
                          maxWidth: "100%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            padding: "5px",
                          }}
                        >
                          <div className={classes.tierDiv}>
                            <Typography
                              align='center'
                              variant='body1'
                              color='initial'
                            >
                              {item.tiers["tier1"]}
                            </Typography>
                          </div>
                          <Divider />
                          <div className={classes.tierDiv}>
                            <Typography
                              align='center'
                              variant='body1'
                              color='initial'
                            >
                              {item.tiers["tier2"]}
                            </Typography>
                          </div>
                          <Divider />
                          <div className={classes.tierDiv}>
                            <Typography
                              align='center'
                              variant='body1'
                              color='initial'
                            >
                              {item.tiers["tier3"]}
                            </Typography>
                          </div>
                          <Divider />
                          <div
                            style={{
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            {/* <Typography
                              align='center'
                              variant='body1'
                              color='secondary'
                            >
                              + {item["rewardOffer"].length} Exciting Rewards
                            </Typography> */}
                            {/*  */}
                            {item["rewardOffer"].map((rewardItem) => {
                              if (
                                rewardItem.description !== "" &&
                                rewardItem.tag !== "" &&
                                rewardItem.link !== ""
                              ) {
                                return (
                                  <div
                                    className={classes.rewardOffer}
                                    key={rewardItem.tag}
                                  >
                                    <Link
                                      target='_blank'
                                      to={{
                                        pathname: rewardItem.link,
                                      }}
                                    >
                                      <Typography
                                        style={{ padding: "5px" }}
                                        variant='body1'
                                        color='initial'
                                        align='center'
                                      >
                                        {rewardItem.description}
                                      </Typography>
                                      <div
                                        style={{
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <Chip label={rewardItem.tag} />
                                      </div>
                                    </Link>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                            {/*  */}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Cards;
